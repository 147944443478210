// Gatsby supports TypeScript natively!
import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image"

export default function Example() {
  return (
    <Layout>
      <Seo title="Gift Cards" />
      <div className="relative bg-white pt-4 lg:pt-16 overflow-hidden pb-16">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">

              <div className="mt-6">
                <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">
                Gift Cards
                </h1>
                <p className="mt-4 text-lg text-gray-500">
                Steam Seattle offers gift cards which can be purchased for detailing services. If your friend or family member is a car lover, our gift cards make a perfect choice.
                <br/><br/>Gift Cards make a perfect choice because they can be redeemed for any of our services. You don't have to worry about getting the wrong thing or not knowing what they want.
                <br/><br/>Steam Seattle's Gift Cards are available all year long! We offer them in various denominations (or you customize) and they make excellent gifts for birthdays, graduations, and other occasions too!
                </p>
              </div>

          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="flex pl-4 px-6 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <StaticImage
                src="../images/gift-card.jpg"
                alt="gift card"
                className="mx-auto w-auto rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:w-auto lg:max-w-none"
                loading="lazy"
                width="600"
              />
            </div>
          </div>
        </div>

      </div>

      <div className="bg-green-500 pb-16">
      <div className="max-w-4xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">Purchase A Gift Card Today</span>
        </h2>
        <p className="mt-4 text-lg font-bold leading-6 text-gray-800">
          Our Portal Below Allows You to Purchase, Check the Balance, and Reload Gift Cards.
        </p>
        <a
          href="https://squareup.com/gift/ATQKRRGTDCGGJ/order"
          rel="noopener noreferrer"
          target="_blank"
          className="mt-8 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-bold rounded-md text-gray-800 bg-white hover:bg-gray-100 sm:w-auto"
        >
          Visit Gift Card Portal
        </a>
      </div>
    </div>

    </Layout>
  );
}
